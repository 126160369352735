<template>
  <div class="works-component">
    <div class="component-nav-icon">
      <!-- <img src="@/assets/icons/works1_header_icon.svg" alt srcset /> -->
      <svg class="works-comp-nav-svg" width="130" height="130" viewBox="0 0 130 130">
        <g id="Group_1646" data-name="Group 1646" transform="translate(-720 -35)">
          <path id="Path_2048" data-name="Path 2048"
            d="M14.333,27a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,14.333,27Z" transform="translate(709 34.667)"
            fill="#6c63ff" />
          <path id="Path_2049" data-name="Path 2049"
            d="M77.667,12a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,77.667,12Z"
            transform="translate(752.333 24.667)" fill="#b14842" />
          <path id="Path_2050" data-name="Path 2050"
            d="M74.667,13a61.667,61.667,0,1,0,61.667,61.667A61.667,61.667,0,0,0,74.667,13Z"
            transform="translate(710 25)" />
          <path id="Path_2051" data-name="Path 2051"
            d="M85.667,11a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,85.667,11Z" transform="translate(754.333 24)"
            fill="#b14842" />
          <path id="Path_2052" data-name="Path 2052"
            d="M88.333,22a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,88.333,22Z"
            transform="translate(758.333 31.333)" fill="#6c63ff" />
          <path id="Path_2053" data-name="Path 2053"
            d="M127.667,84A3.333,3.333,0,1,0,131,87.333,3.333,3.333,0,0,0,127.667,84Zm-110-25a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,17.667,59Z"
            transform="translate(709 56)" fill="#b14842" />
          <path id="Path_2054" data-name="Path 2054"
            d="M26.333,85a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,26.333,85Z" transform="translate(717 73.333)"
            fill="#6c63ff" />
          <path id="Path_2055" data-name="Path 2055"
            d="M20.167,63a4.167,4.167,0,1,0,4.167,4.167A4.167,4.167,0,0,0,20.167,63Zm4.167,25A1.667,1.667,0,1,0,26,89.667,1.667,1.667,0,0,0,24.333,88Zm98.333-55a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,122.667,33Z"
            transform="translate(712.333 38.667)" fill="#fff" />
          <g id="noun_develop_78507" transform="translate(416 770.7)">
            <g id="Group_1292" data-name="Group 1292" transform="translate(334 -697.7)">
              <path id="Path_2397" data-name="Path 2397"
                d="M68.389,13.3H11.611a6.442,6.442,0,0,0-6.533,5.627c0,.219-.078.365-.078.585V52.18a6.415,6.415,0,0,0,6.611,6.212H68.389a6.442,6.442,0,0,0,6.533-5.627c0-.219.078-.365.078-.585V19.512A6.415,6.415,0,0,0,68.389,13.3Zm-2.8,41.949H13.867a5.545,5.545,0,0,1-5.678-5.335V21.851c0-2.923,3.033-5.335,6.144-5.335H66.056a5.545,5.545,0,0,1,5.678,5.335V49.914C71.811,52.837,68.778,55.249,65.589,55.249Z"
                transform="translate(-5 -13.3)" />
              <path id="Path_2398" data-name="Path 2398"
                d="M59.922,79.2H30.833A1.6,1.6,0,0,0,29.2,80.735V83.8a1.6,1.6,0,0,0,1.633,1.535H60A1.6,1.6,0,0,0,61.633,83.8V80.735A1.724,1.724,0,0,0,59.922,79.2Z"
                transform="translate(-10.378 -31.039)" fill="#ef7974" />
              <path id="Path_2399" data-name="Path 2399"
                d="M35.756,49.254a.693.693,0,0,1-1.011,0l-10.111-9.5a.6.6,0,0,1,0-.95l10.111-9.5a.692.692,0,0,1,1.011,0l1.089,1.023a.6.6,0,0,1,0,.95l-8.556,8.039,8.556,8.039a.6.6,0,0,1,0,.95Zm5.833,3.508a.627.627,0,0,1-.856.438l-1.322-.365a.582.582,0,0,1-.467-.8l8.089-26.31a.628.628,0,0,1,.856-.438l1.322.365a.626.626,0,0,1,.467.8Zm12.289-3.508a.692.692,0,0,1-1.011,0l-1.089-1.023a.6.6,0,0,1,0-.95l8.556-8.039L51.778,31.2a.6.6,0,0,1,0-.95l1.089-1.023a.692.692,0,0,1,1.011,0l10.111,9.5a.6.6,0,0,1,0,.95Z"
                transform="translate(-9.311 -16.513)" />
            </g>
          </g>
        </g>
      </svg>
      <div class="c-n-title">
        <span>MY</span>
        <span>&nbsp;</span>
        <span>WORKS</span>
      </div>
    </div>

    <div class="w-c-content">
      <div class="w-c-c-title">
        <span>1.</span>
        <span>&nbsp;</span>
        <span>ACCENTURE AI</span>
      </div>
      <div class="w-c-c-tiles-body">
        <v-container fluid ma-0 pa-0>
          <v-row dense>
            <v-col class="pa-2 pb-8" cols="12" sm="6" md="6" lg="4">
              <v-card class="w-c-c-tile-card elevation-4" width="425" @click="onClickTile('deliveryInaccuracy')">
                <v-card-title>
                  Delivery Inaccuracy
                  <span>An Next.js Application</span>
                </v-card-title>
                <v-card-text class="deliveryApp_bg">Reporting, modelling application for store operators, managers &
                  corporate users to understand the restaurant performance, order inaccuracies & help the stores to
                  identify the common areas of mistake.</v-card-text>
                <v-divider class="mx-0"></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip class="mx-2 tile-tech-chip1" small>Next.js</v-chip>
                  <v-chip class="mx-2 tile-tech-chip2" small>React.js</v-chip>
                  <v-chip class="mx-2 tile-tech-chip3" small>Chart.js</v-chip>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col class="pa-2 pb-8" cols="12" sm="6" md="6" lg="4">
              <v-card class="w-c-c-tile-card elevation-4" width="425" @click="onClickTile('mcinsights')">
                <v-card-title>
                  MCINSIGHTS
                  <span>An Vue.js Application</span>
                </v-card-title>
                <v-card-text class="mcinsights_bg">
                  Integrated & Unified global view of
                  all data & insights products.
                  (Reports, Apps, Knowledgebase)
                </v-card-text>
                <v-divider class="mx-0"></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip class="mx-2 tile-tech-chip1" small>Vue</v-chip>
                  <v-chip class="mx-2 tile-tech-chip2" small>VueX</v-chip>
                  <v-chip class="mx-2 tile-tech-chip3" small>Vuetify</v-chip>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col class="pa-2 pb-8" cols="12" sm="6" md="6" lg="4">
              <v-card class="w-c-c-tile-card elevation-4" width="425" @click="onClickTile('compassionateHR')">
                <v-card-title>
                  COMPASSIONATE HR
                  <span>A jQuery Application</span>
                </v-card-title>
                <v-card-text class="hr_bg">
                  <span>Website built for the HR team to understand the situation where compassion is needed & the
                    responsibilities in those situations.</span>
                </v-card-text>
                <v-divider class="mx-0"></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip class="mx-2 tile-tech-chip1" small>jQuery</v-chip>
                  <v-chip class="mx-2 tile-tech-chip2" small>Relax</v-chip>
                  <v-chip class="mx-2 tile-tech-chip3" small>SCSS</v-chip>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col class="pa-2 pb-8" cols="12" sm="6" md="6" lg="4">
              <v-card class="w-c-c-tile-card elevation-4" width="425" @click="onClickTile('zbx')">
                <v-card-title>
                  ZERO QUARTILE
                  <span>An React.js Application</span>
                </v-card-title>
                <v-card-text class="zq_bg">
                  <span>An Expense Management application. It’s a way to identify the expense drivers through the
                    expense profile and ultimately identify the cost savings.</span>
                </v-card-text>
                <v-divider class="mx-0"></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip class="mx-2 tile-tech-chip1" small>React</v-chip>
                  <v-chip class="mx-2 tile-tech-chip2" small>Redux</v-chip>
                  <v-chip class="mx-2 tile-tech-chip3" small>D3.js</v-chip>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="w-c-c-title">
        <span>2.</span>
        <span>&nbsp;</span>
        <span>KOGENTIX INC.</span>
      </div>
      <div class="w-c-c-tiles-body">
        <v-container fluid ma-0 pa-0>
          <v-row dense>
            <v-col class="pa-2 pb-8" cols="12" sm="6" md="6" lg="4">
              <v-card class="w-c-c-tile-card elevation-4" width="425" @click="onClickTile('iot')">
                <v-card-title>
                  IOT
                  <span>An Angular Application</span>
                </v-card-title>
                <v-card-text class="iot_bg">
                  <span>An Asset Management application. It tracks and manages asset and various behaviour of the
                    asset.</span>
                </v-card-text>
                <v-divider class="mx-0"></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip class="mx-2 tile-tech-chip1" small>Angular</v-chip>
                  <v-chip class="mx-2 tile-tech-chip2" small>TypeScript</v-chip>
                  <v-chip class="mx-2 tile-tech-chip3" small>Material</v-chip>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col class="pa-2 pb-8" cols="12" sm="6" md="6" lg="4">
              <v-card class="w-c-c-tile-card elevation-4" width="425" @click="onClickTile('amp')">
                <v-card-title>
                  AMP
                  <span>An Angular.js Application</span>
                </v-card-title>
                <v-card-text class="amp_bg">
                  <span>Automated Machine Learning Platform (AMP) - helps organizations build AI applications that learn
                    business, make timely recommendations to improve KPI’s.</span>
                </v-card-text>
                <v-divider class="mx-0"></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip class="mx-2 tile-tech-chip1" small>Angular.js</v-chip>
                  <v-chip class="mx-2 tile-tech-chip2" small>Bootstrap</v-chip>
                  <v-chip class="mx-2 tile-tech-chip3" small>SCSS</v-chip>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="w-c-c-title">
        <span>3.</span>
        <span>&nbsp;</span>
        <span>FLUTURA</span>
      </div>
      <div class="w-c-c-tiles-body">
        <v-container fluid ma-0 pa-0>
          <v-row dense>
            <v-col class="pa-2 pb-8" cols="12" sm="6" md="6" lg="4">
              <v-card class="w-c-c-tile-card elevation-4" width="425" @click="onClickTile('supplyChain')">
                <v-card-title>SUPPLY CHAIN FORENSICS</v-card-title>
                <v-card-text class="sc_bg">
                  <span>Tool that allows you to manage all components and finished goods part numbers.</span>
                </v-card-text>
                <v-divider class="mx-0"></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip class="mx-2 tile-tech-chip1" small>Django</v-chip>
                  <v-chip class="mx-2 tile-tech-chip2" small>jQuery</v-chip>
                  <v-chip class="mx-2 tile-tech-chip3" small>Bootstrap</v-chip>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col class="pa-2 pb-8" cols="12" sm="6" md="6" lg="4">
              <v-card class="w-c-c-tile-card elevation-4" width="425" @click="onClickTile('assetHealthMonitor')">
                <v-card-title>ASSET HEALTH MONITOR</v-card-title>
                <v-card-text class="ahm_bg">
                  <span>The application provides instant insight to issues that need immediate attention for all
                    components in the solar panel ecosystem.</span>
                </v-card-text>
                <v-divider class="mx-0"></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip class="mx-2 tile-tech-chip1" small>Django</v-chip>
                  <v-chip class="mx-2 tile-tech-chip2" small>jQuery</v-chip>
                  <v-chip class="mx-2 tile-tech-chip3" small>Bootstrap</v-chip>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col class="pa-2 pb-8" cols="12" sm="6" md="6" lg="4">
              <v-card class="w-c-c-tile-card elevation-4" width="425" @click="onClickTile('solarOperationsWatch')">
                <v-card-title>SOLAR OPERATIONS WATCH</v-card-title>
                <v-card-text class="sow_bg">
                  <span>Developed an analytical product for the ‘Internet of Things’ infrastructure which aids the end
                    user ensure the system will not run out of energy.</span>
                </v-card-text>
                <v-divider class="mx-0"></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip class="mx-2 tile-tech-chip1" small>Django</v-chip>
                  <v-chip class="mx-2 tile-tech-chip2" small>jQuery</v-chip>
                  <v-chip class="mx-2 tile-tech-chip3" small>Bootstrap</v-chip>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <WorkPrompt v-if="tileClicked" :tile="clickedName" :value="tileClicked" :data="tileData"
      @closePrompt="closePrompt" />
  </div>
</template>

<script>
import WorkPrompt from '@/components/Prompt/WorkPrompt'
import worksJson from "@/components/JSON/works.json";

export default {
  name: "Works",
  components: {
    WorkPrompt
  },
  data() {
    return {
      tileClicked: false,
      clickedName: "",
      tileData: {}
    }
  },
  methods: {
    onClickTile(name) {
      this.tileData = worksJson[name];
      this.tileData.key = name
      this.clickedName = name;
      this.tileClicked = true;
    },
    closePrompt() {
      this.tileClicked = false;
    }
  }
}
</script>
