<template>
  <v-dialog v-model="value" width="1400" persistent content-class="works-dialog">
    <v-card class="w-d-card">
      <v-card-title class="w-d-title">
        <span>{{ data.duration }}</span>
        <span>{{ data.title }}</span>
        <svg width="30" height="30" viewBox="0 0 37 37" class="w-d-close" @click="onClickClose">
          <g id="close" transform="translate(-17.575 -18)">
            <path id="Path_2585" data-name="Path 2585"
              d="M47.606,54.862h-22.2a6.851,6.851,0,0,1-6.831-6.831v-22.2A6.851,6.851,0,0,1,25.406,19h22.2a6.851,6.851,0,0,1,6.831,6.831v22.2A6.851,6.851,0,0,1,47.606,54.862Z"
              transform="translate(-0.431 -0.431)" />
            <path id="Path_2586" data-name="Path 2586"
              d="M47.175,55h-22.2a7.409,7.409,0,0,1-7.4-7.4V25.4a7.409,7.409,0,0,1,7.4-7.4h22.2a7.409,7.409,0,0,1,7.4,7.4V47.6A7.409,7.409,0,0,1,47.175,55Zm-22.2-35.862A6.269,6.269,0,0,0,18.713,25.4V47.6a6.269,6.269,0,0,0,6.262,6.262h22.2A6.269,6.269,0,0,0,53.437,47.6V25.4a6.269,6.269,0,0,0-6.262-6.262Z"
              transform="translate(0 0)" fill="#6d5856" />
            <path id="Path_2587" data-name="Path 2587"
              d="M53.813,37.731V48.354a5.9,5.9,0,0,1-5.884,5.884H28.959a5.9,5.9,0,0,1-5.884-5.884V29.384A5.9,5.9,0,0,1,28.959,23.5H48.121"
              transform="translate(-2.369 -2.369)" fill="#ef7974" opacity="0" />
            <path id="Path_2588" data-name="Path 2588"
              d="M47.714,54.308H28.744a6.175,6.175,0,0,1-6.169-6.169V29.169A6.175,6.175,0,0,1,28.744,23H47.906a.285.285,0,0,1,0,.569H28.744a5.606,5.606,0,0,0-5.6,5.6v18.97a5.606,5.606,0,0,0,5.6,5.6h18.97a5.606,5.606,0,0,0,5.6-5.6V37.515a.285.285,0,1,1,.569,0V48.139A6.176,6.176,0,0,1,47.714,54.308ZM53.6,36.092a.285.285,0,0,1-.285-.285V33.531a.285.285,0,0,1,.569,0v2.277A.285.285,0,0,1,53.6,36.092Zm0-3.415a.285.285,0,0,1-.285-.285V31.254a.285.285,0,1,1,.569,0v1.138A.285.285,0,0,1,53.6,32.677Z"
              transform="translate(-2.154 -2.154)" fill="#6d5856" />
            <path id="Path_2589" data-name="Path 2589"
              d="M52,50.012l-6.038-6.038L52,37.937a.569.569,0,0,0-.8-.8L45.156,43.17l-6.44-6.44a.569.569,0,1,0-.8.8l6.44,6.44-6.44,6.44a.569.569,0,0,0,.8.8l6.44-6.44,6.038,6.038a.569.569,0,1,0,.8-.8Z"
              transform="translate(-8.689 -7.996)" fill="#fff" />
            <path id="Path_2590" data-name="Path 2590"
              d="M37.883,36.133a.566.566,0,0,1,.4.166l6.44,6.44L50.763,36.7a.569.569,0,0,1,.8.8l-6.038,6.037,6.038,6.038a.569.569,0,0,1-.8.8L44.726,44.35l-6.44,6.44a.569.569,0,1,1-.8-.8l6.44-6.44L37.48,37.1a.569.569,0,0,1,.4-.971m0-.569a1.138,1.138,0,0,0-.8,1.943l6.038,6.038-6.038,6.037a1.138,1.138,0,0,0,1.61,1.61l6.038-6.038,5.635,5.635a1.138,1.138,0,0,0,1.61-1.61l-5.635-5.635,5.635-5.635a1.138,1.138,0,0,0-1.61-1.61l-5.635,5.635L38.688,35.9a1.132,1.132,0,0,0-.805-.332Z"
              transform="translate(-8.258 -7.566)" fill="#fff" stroke="#fff" stroke-width="0.5" />
          </g>
        </svg>
      </v-card-title>

      <v-card-text class="w-d-text">
        <v-row>
          <v-col v-for="n in data.images" :key="n" class="d-flex child-flex w-d-t-col" cols="6" sm="4" md="4" lg="4">
            <v-card>
              <v-img :src="require(`@/assets/works/${data.key}/${n}`)"
                :lazy-src="require(`@/assets/works/${data.key}/${n}`)" aspect-ratio="2" class="grey lighten-2">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "WorkPrompt",
  props: {
    tile: {
      required: true
    },
    value: {
      required: true
    },
    data: {
      required: true
    }
  },
  data() {
    return {
      dialog: true
    }
  },
  methods: {
    onClickClose() {
      this.$emit("closePrompt");
    },
  }
}
</script>

<style lang="scss" >
:root {
  .w-d-close {
    #Path_2585 {
      fill: #ef7974;
      opacity: 0.596;
    }
  }
}

:root.dark-theme {
  .w-d-close {
    #Path_2585 {
      fill: #F8BF7B;
      opacity: 1;
    }
  }
}
</style>